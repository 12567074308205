<template>
  <div class="moduleBindingManage">
    <ds-header title="首页皮肤管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目">
        <a-tree-select
          v-model="params.projectId"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
      <a-form-item label="皮肤名称：">
        <a-input v-model.trim="params.skinName" placeholder="请输入模块名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="生效时间：">
        <a-range-picker
            format="YYYY-MM-DD"
            v-model="params.effectiveTime"
        >
        </a-range-picker>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          v-model="params.status"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in state" :key="option.id" :value="option.id">{{
            option.value
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否默认：">
        <a-select
          v-model="params.isDefault"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in defaultList" :key="option.id" :value="option.id">{{
            option.value
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="透出端：">
        <a-select
          v-model="params.penetrate"
          class="searchInput"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option :value="1"> APP </a-select-option>
          <a-select-option :value="2"> 小程序 </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 添加 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button
            type="link"
            class="btnClass"
            @click="view(record)"
          >
            查看
          </a-button>
          <a-button
            type="link"
            class="btnClass"
            @click="updateSkin(record)"
          >
            编辑
          </a-button>
          <a-button type="link" class="btnClass" @click="disableorEnabled(record)">
            {{ record.status == 1 ? "禁用" : "启用" }}
          </a-button>
          <a-button
            type="link"
            class="btnClass"
            @click="delSkin(record)"
          >
            删除
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { mapState } from "vuex";
import {
  listSkin,
  deleteSkin,
  enabledSkin,
} from "@/api/skinManage";
import moment from 'moment/moment';

export default {
  name: "skinManage",
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    })
  },
  // watch: {
  //   projectList: {
  //     handler(val) {
  //       if(val && val.length) {
  //         this.params.projectId = val[0].id
  //         this.searchFormList();
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  data() {
    return {
      params: {
        // 项目名称
        projectId: undefined,
        // 皮肤名称
        skinName: "",
        // 状态
        status: undefined,
        // 是否默认
        isDefault: undefined,
        // 活动时间
        effectiveTime: [],
        // 透出端
        penetrate: undefined
      },
      projectName: [],
      // 默认
      defaultList: [
        {
          id: 0,
          value: "否",
        },
        {
          id: 1,
          value: "是",
        },
      ],
      // 状态
      state: [
        {
          id: 0,
          value: "禁用",
        },
        {
          id: 1,
          value: "启用",
        },
      ],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "适用项目",
            dataIndex: "projectName",
            key: "projectName",
            width:'200px'
          },
          {
            title: "皮肤名称",
            dataIndex: "skinName",
            key: "skinName",
            width:'200px'
          },
          {
            title: '透出端',
            dataIndex: 'penetrate',
            customRender(text) {
              return text === 1 ? 'APP' : '小程序'
            },
              width:'100px'
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            customRender(text) {
              return text == 0 ? "禁用" : "启用";
            },
              width:'100px'
          },
          {
            title: "是否默认",
            dataIndex: "isDefault",
            key: "isDefault",
            customRender(text) {
              return text == 0 ? "否" : "是";
            },
            width:'100px'
          },
          {
            title: "生效时间",
            dataIndex: "effectiveStartTime",
            key: "effectiveStartTime",
            customRender(text, record) {
              if (record.isDefault == 0) {
                if (record.effectiveStartTime !== null) {
                  return (
                    moment(record.effectiveStartTime).format("YYYY-MM-DD HH:mm:ss") + " 至 " +
                    moment(record.effectiveEndTime).format("YYYY-MM-DD HH:mm:ss")
                  );
                }
              }
            },
            width:'300px'
          },
          {
            title: "更新人",
            dataIndex: "updateBy",
            key: "updateBy",
            width:'100px'
          },
          {
            title: "更新时间",
            dataIndex: "updateTime",
            key: "updateTime",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
            width:'300px'
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed:'right'
          }
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  created() {
    this.searchFormList();
  },
  methods: {
    //禁用启用
    async disableorEnabled(text) {
      let that = this;
      this.$confirm({
        title: `确定要${text.status == 1 ? "禁用" : "启用"}该【 ${text.skinName} 】 皮肤吗？`,
        onOk() {
          var data = {};
          data.id = text.id;
          data.status = text.status;
          data.status == 1 ? (data.status = 0) : (data.status = 1);
          enabledSkin(data).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error(res.msg);
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 删除
    delSkin(record){
      let that = this;
      this.$confirm({
        title: "确定要删除该 【 "+record.skinName+" 】 皮肤吗？",
        onOk() {
          var data = {};
          data.id = record.id;
          deleteSkin(data).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error(res.msg);
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 编辑
    updateSkin(record) {
      this.$router.push({
        path: "/pageManagement/skinManage/add",
        query: {
          type: "edit",
          id: record.id
        },
      });
    },

    // 查看
    view(record) {
      this.$router.push({
        path: "/pageManagement/skinManage/add",
        query: {
          type: "view",
          id: record.id
        },
      });
    },

    // 新增
    add() {
      this.$router.push({
        path: "/pageManagement/skinManage/add",
        query: {
          type: "add",
        },
      });
    },

    // 重置
    resetSearchForm() {
      this.table.pagination.current = 1
      this.table.pagination.pageSize = 10
      this.params = {
            projectId: undefined,
            skinName: "",
            status: undefined,
            isDefault: undefined,
            effectiveTime: [],
            penetrate: undefined
      }
      this.searchFormList();
    },

    // 查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 处理获取列表时数据
    async searchFormList() {
      let data = {};
      if(this.params.skinName) {
        data.skinName = this.params.skinName
      }
      if(this.params.projectId) {
        data.projectId = this.params.projectId
      }
      if(this.params.effectiveTime && this.params.effectiveTime.length != 0) {
        data.effectiveStartTime =  moment(this.params.effectiveTime[0]).format('YYYY-MM-DD');
        data.effectiveEndTime =  moment(this.params.effectiveTime[1]).format('YYYY-MM-DD');
      }
      if(this.params.status === 0 || this.params.status === 1) {
        data.status = this.params.status;
      }
      if(this.params.isDefault === 0 || this.params.isDefault === 1) {
        data.isDefault = this.params.isDefault;
      }
      if (this.params.penetrate) {
        data.penetrate = this.params.penetrate
      }
      data.pageNum = this.table.pagination.current;
      data.pageSize = this.table.pagination.pageSize
      const res = await listSkin(data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
      }
    },

    // 项目下拉框
    // async projectList() {
    //   const res = await projectList();
    //   this.projectName = res.data.items;
    // },

    // 分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },

  },
  
  mounted() {
    let handleResize = ()=> {
      let width = document.getElementsByClassName('moduleBindingManage')[0].clientWidth;
      console.log(width,'=====');
      if(width>1600){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>

<style lang="scss" scoped>
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.searchInput {
  width: 200px;
}
.inputClass {
  width: 280px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
